@font-face {
    font-family: 'Gilroy',sans-serif;
    src: local('Gilroy'), url(../../assets/fonts/Gilroy-ExtraBold.otf) format('opentype');
}

.downbar-card {
    background: #FAFAFA;
    box-shadow: 0px -5px 20px rgb(0 0 0 / 25%);
    border-radius: 10px 10px 0px 0px;
    min-height: 40vh;
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 66.66666667% !important;
    bottom: 0;
    transition: 550ms;
}

@media (max-width: 992px) {
    .downbar-card {
        width: 100% !important;
    }
}

@media (width: 992px) {
    .downbar-card {
        width: 66.66666667% !important;
    }
}

.downbar-card.hide {
    bottom: -100%;
    transition: 550ms;
}

.downbar-card-overlay1 {
    position: absolute;
    width: 100%;
    top: 100%;
    height: 100%;
    top: 0;
    bottom: 100%;
    right: 0;
    left: 0;
    background-color: rgb(0 0 0 / 0.8);
    z-index: 1;
    cursor: pointer;
    transition: 550ms;
}

@keyframes showUp {
    0% {
        height: 0%;

    }

    100% {

        height: 100%;
        top: 0;
    }
}

.downbar-card-overlay1-hide {
    top: 100%;
    height: 0%;
    z-index: 0;
    transition: 550ms;
}

.downbar-card-overlay1.hide {
    top: 100%;
    height: 0%;
    z-index: 0;
    transition: 550ms;
}

@keyframes hideOverlay {
    100% {
        top: 100%;
        height: 0%;
        z-index: 0;
    }
}

.downbar-card-body-header {
    font-weight: 700;
    font-size: 0.7rem;
    line-height: 15px;
    text-transform: uppercase;
    color: #444444;
    opacity: 0.5;
    padding: 0 0 0.7rem 0;

}

.downbar-card-body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 0 1.5rem;
    margin: 0 0 1.5rem 0;
}

.downbar-card-body-fixwidth1 {
    width: 35%;
    color: #444444;
    font-weight: 700;
    font-size: 0.88rem;
    line-height: 2rem;
    padding: 0 5px 0 0;
}

.downbar-card-body-fixwidth2 {
    width: 65%;
    color: #444444;
    font-weight: 400;
    font-size: 0.9rem;
    line-height: 1.5rem;
}

.downbar-card-body-fixwidth3 {
    text-align: center;
    width: auto;
    line-height: 1.5rem;
    background: #29CF81;
    font-weight: 700;
    font-size: 0.8rem;
    border-radius: 20px;
    color: #FFFFFF;
    padding: 0.2rem 0.5rem;
}

.rectangle-card-body-top-content1-setUp-done {
    font-weight: 700;
    font-size: 0.8rem;
    border-radius: 20px;
    color: #FFFFFF;
    padding: 0.2rem 0.5rem;
}

.downbar-card-secondary-content1 {
    padding: 0 1.5rem;
    display: flex;
    flex-direction: row;
}

.downbar-card-secondary-content1-icon {
    background-color: #FF9777;
    border-radius: 50%;
    align-self: center;
    display: grid;
    justify-content: center;
    align-items: center;
    height: 2.5rem;
    width: 2.5rem;
}

.downbar-card-secondary-content1-close-icon {
    position: absolute;
    left: calc(100% - 2rem);
    top: 1.5rem;
    background: transparent;
    border: none;
}

.downbar-card-secondary-content1-item2 {
    display: flex;
    flex-direction: column;
    margin: 1rem;
}

.downbar-card-secondary-content1-item2-floor-name {
    font-weight: 700;
    font-size: 1rem;
    color: #161056;
    font-family: 'Gilroy';
}

.downbar-card-secondary-content1-item2-time {
    color: rgba(68, 68, 68, 0.75);
    font-weight: 400;
    font-size: 0.85rem;
}